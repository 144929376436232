<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Хайх ангилал жагсаалт
      </h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="dialogFormVisible = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <!-- <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            /> -->
          </div>
          <div align="right">
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table
            :data="
              categoryList.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
            :default-sort="{ prop: 'date', order: 'descending' }"
            size="mini"
            style="width: 100%"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="name_mon" label="Нэр /монгол/" sortable>
            </el-table-column>
            <el-table-column prop="name_eng" label="Нэр /англи/" sortable>
            </el-table-column>
            <el-table-column prop="service" label="Төрөл"> </el-table-column>
            <el-table-column
              prop="createdAt"
              :formatter="formatter"
              label="Үүсгэсэн огноо"
            >
            </el-table-column>
            <el-table-column prop="sort" label="Эрэмбэ" sortable>
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteSearchCategory(scope.row)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Апп дээрх категори" :visible.sync="dialogFormVisible">
      <el-form :model="category">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="category.name_mon"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="category.name_eng"
            placeholder="категори англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Эрэмбэ">
          <el-input-number
            :min="0"
            v-model="category.sort"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Категорийн төрөл">
          <el-select v-model="category.service" placeholder="төрөл сонгох">
            <!-- <el-option
              v-for="item in service"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            > -->
            <!-- </el-option> -->
            <el-option
              v-for="service in servicesList"
              :label="service.name_mon"
              :value="service.name_eng"
              :key="service.name_eng"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="addSearchCategory()">Нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Апп дээрх категори засах"
      :visible.sync="dialogEditFormVisible"
    >
      <el-form :model="editCategory">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="editCategory.name_mon"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="editCategory.name_eng"
            placeholder="категори англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Эрэмбэ">
          <el-input-number
            :min="0"
            v-model="editCategory.sort"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Категорийн төрөл">
          <el-select v-model="editCategory.service" placeholder="төрөл сонгох">
            <!-- <el-option
              v-for="item in service"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> -->
            <el-option
              v-for="service in servicesList"
              :label="service.name_mon"
              :value="service.name_eng"
              :key="service.name_eng"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="updateEdit()">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  created() {
    this.getSearchCategories();
    this.getServices();
  },
  data() {
    return {
      categoryList: [],
      search: "",
      service: [
        {
          value: "food",
          label: "Хоол"
        },
        {
          value: "goods",
          label: "Ахуйн бараа"
        },
        {
          value: "drinks",
          label: "Шингэн хүнс"
        },
        {
          value: "grocery",
          label: "Хүнс"
        },
        {
          value: "gift",
          label: "Бэлэг"
        },
        {
          value: "book",
          label: "Ном"
        },
        {
          value: "pharmacy",
          label: "Эмийн сан"
        },
        {
          value: "delivery",
          label: "Хүргэлт"
        }
      ],
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      category: {
        name_mon: "",
        name_eng: "",
        sort: 0,
        deleted: "",
        createdAt: "",
        updatedAt: "",
        service: ""
      },
      editCategory: {
        id: 0,
        name_mon: "",
        name_eng: "",
        sort: 0,
        deleted: "",
        createdAt: "",
        updatedAt: "",
        service: ""
      },
      deleteSendData: {
        id: 0
      },
      servicesList: []
    };
  },
  methods: {
    closeProgress() {
      this.dialogFormVisible = false;
      this.dialogEditFormVisible = false;
    },
    getSearchCategories() {
      service.getSearchCategories().then(res => {
        this.categoryList = res;
      });
    },
    formatter(row) {
      var convertDate = row.createdAt.substring(-6, 10);
      return convertDate;
    },
    addSearchCategory() {
      this.dialogFormVisible = true;
      var today = new Date();
      this.category.createdAt =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      service.addSearchCategory(this.category).then(response => {
        if (response.id !== null) {
          this.$notify({
            title: "Амжилттай",
            message: "Категори мэдээлэл шинээр нэмэгдлээ",
            type: "success"
          });
          this.closeProgress();
        } else if (response.id == null) {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Категори мэдээлэл нэмэхэд алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    showEdit(row) {
      this.editCategory = row;
      this.dialogEditFormVisible = true;
    },
    updateEdit() {
      var today = new Date();
      this.editCategory.updatedAt =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      service.updateSearchCategory(this.editCategory).then(response => {
        if (response.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Категорийн мэдээлэл засагдлаа",
            type: "success"
          });
          this.closeProgress();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Категорийн мэдээлэл засахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    deleteSearchCategory(data) {
      this.deleteSendData.id = data.id;
      this.$confirm("Устгахдаа итгэлтэй байна уу?", data.name_mon, {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteSearchCategory(this.deleteSendData).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Категори устгагдлаа",
                type: "success"
              });
              this.getSearchCategories();
            } else if (response.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Категори устгахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>
